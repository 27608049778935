import type { INodeGroupTableModal } from '@manager'

export const getModalConfig = (
  action: boolean | INodeGroupTableModal | undefined,
  defaultValue: INodeGroupTableModal,
): Required<INodeGroupTableModal> => {
  const _defaultValue = {
    title: defaultValue.title ?? '',
    body: defaultValue.body ?? '',
    confirmButton: defaultValue.confirmButton ?? '',
  }

  if (typeof action === 'boolean') {
    return _defaultValue
  }

  return {
    ..._defaultValue,
    ...action,
  }
}
